import React from "react";
import "../styles/Section4.css";
import { SocialIcon } from "react-social-icons";

function Section4() {
  return (
    <section id="contact" className="section4">
      <h2>Contact Me</h2>
      <div className="email_container">
        <p>You can reach me at :</p>
        <a href="mailto:abhayaku21@gmail.com">abhayaku21@gmail.com</a>
      </div>

      <div className="social-links">
        <SocialIcon url="https://www.linkedin.com/in/abhayaku/" />
        <SocialIcon url="https://github.com/Abhayaku" />
        <SocialIcon url="https://www.reddit.com/user/abhayakku" />
        <SocialIcon url="https://x.com/abhay_aku" />
        <SocialIcon url="https://www.youtube.com/@abhayaku20" />
      </div>

      <a
        href="https://drive.google.com/file/d/1OLptcJDn4sfB85C4w27-vhT7gtHD4trJ/view?usp=sharing"
        target="_blank"
        rel="noreferrer"
        className="download-button"
      >
        View Resume
      </a>
    </section>
  );
}

export default Section4;
