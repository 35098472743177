import React from "react";
import "../styles/Section3.css";
import ServiceImage from "../assets/services.webp";

function Section3() {
  const services = [
    {
      icon: ServiceImage,
      title: "Full Stack Development",
      description: "Building complete solutions from front-end to back-end.",
    },
    {
      icon: ServiceImage,
      title: "Front-end Development",
      description: "Crafting interactive and user-friendly interfaces.",
    },
    {
      icon: ServiceImage,
      title: "Backend Development",
      description: "Developing secure and scalable server-side systems.",
    },
    {
      icon: ServiceImage,
      title: "Web Development",
      description: "Creating modern and responsive websites.",
    },
    {
      icon: ServiceImage,
      title: "Mobile App Development",
      description: "Building mobile apps for Android and iOS platforms.",
    },
    {
      icon: ServiceImage,
      title: "Tech Solutions",
      description: "Providing innovative tech solutions for businesses.",
    },
  ];

  return (
    <section id="services" className="section3">
      <h2>Tech Services</h2>
      <div className="services">
        {services.map((service, index) => (
          <div className="service" key={index}>
            <img src={service.icon} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Section3;
