import React from "react";
import "../styles/Section1.css";
import Video from "../assets/bg.mp4";
import ProfileImage from "../assets/profile.jpg";

function Section1() {
  return (
    <section id="aboutme" className="section1">
      <video autoPlay loop muted>
        <source src={Video} type="video/mp4" />
      </video>
      <div className="overlay">
        <div className="content">
          <div className="left">
            <h1>Hello, I'm Abhay Upadhyay 👋</h1>
            <p>
              I work as a full-stack developer for Web, Android, and iOS apps,
              where I find joy in coding. My proficiency spans various
              technologies, including C, Java, C#, JavaScript, Typescript, HTML
              (markup language) and CSS (style sheet language). I've also gained
              experience with JavaScript libraries and frameworks like React JS,
              Next JS, and React Native, as well as runtime environments such as
              Node JS. Despite my broad skill set, I'm always eager to learn and
              stay updated with emerging technologies.
            </p>
          </div>
          <div className="right">
            <img src={ProfileImage} alt="Your Portrait" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section1;
