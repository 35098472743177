import React, { useState } from "react";
import "../styles/NavBar.css";
import Logo from "../assets/logo.png";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <div className="logo">
        <a href="/">
          <img src={Logo} alt="Your Portrait" width={30} height={30} />
        </a>
      </div>
      <div className={`nav-links ${isOpen ? "open" : ""}`}>
        <a href="#aboutme" onClick={toggleMenu}>
          About Me
        </a>
        <a href="#projects" onClick={toggleMenu}>
          Projects
        </a>
        <a href="#services" onClick={toggleMenu}>
          Services
        </a>
        <a href="#contact" onClick={toggleMenu}>
          Contact
        </a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
    </nav>
  );
}

export default NavBar;
