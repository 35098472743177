import React from "react";
import "../styles/Section2.css";
import CareerImage from "../assets/career.jpg";
import ObjectImage from "../assets/object.png";
import BookImage from "../assets/book.jpg";
import MediatorImage from "../assets/mediator.jpg";
import HelicopterImage from "../assets/helicopter.jpg";
import TicTacToeImage from "../assets/tic_tac_toe.png";
import RPSImage from "../assets/rock_paper_scissor.png";

function Section2() {
  const projects = [
    {
      image: CareerImage,
      title: "Career Information Provider",
      description:
        "Career Information Provider is a web app designed to guide school students toward their dream careers. It offers tailored information on subjects, courses, and top universities aligned with their career goals. The platform helps students make informed academic choices, bridging aspirations with actionable steps for a successful future.",
    },
    {
      image: ObjectImage,
      title: "Object Detection",
      description:
        "This project aims to make object detection and tracking-based surveillance systems accessible to everyone. It is a low-cost, offline application with a user-friendly, interactive interface. Computer vision, like a human brain understanding visual frames, uses specialized methods to analyze and interpret images, known as image analysis or scene understanding.",
    },
    {
      image: BookImage,
      title: "MERI PathShala",
      description:
        "This application helps students who miss classes by providing access to recorded lectures. Teachers can also manage students' academic records and activities. The platform facilitates interaction between students and teachers, as well as among students, ensuring a collaborative learning environment and comprehensive academic management.",
    },
    {
      image: MediatorImage,
      title: "Mediator",
      description:
        "This chatting application enables users to communicate with each other directly, similar to a standard chat app. The primary focus is on user-to-user chatting, with no group chat functionality planned unless additional development time is available. It offers a straightforward and effective way for users to connect individually.",
    },
    {
      image: HelicopterImage,
      title: "Helicopter 2D",
      description:
        "This application is created for fun, focusing on developing new skills in React Native and improving existing ones. It offers a creative way to practice and grow in React Native development while enjoying the process. Dive in, explore new techniques, and have fun with this engaging experience!",
    },
    {
      image: TicTacToeImage,
      title: "Tic Tac Toe",
      description:
        "Tic-Tac-Toe, also called noughts and crosses or Xs and Os, is a classic two-player game. I developed it for fun and out of my interest in game development. It's a simple, yet enjoyable game designed to provide a fun experience. Enjoy playing and challenge your friends!",
    },
    {
      image: RPSImage,
      title: "Rock Paper Scissor",
      description:
        "Rock Paper Scissors is a hand game typically played between two people. Each player simultaneously chooses one of three shapes—rock, paper, or scissors—with an outstretched hand. The game is simple, strategic, and quick, making it a classic and fun challenge between friends. Enjoy playing!",
    },
  ];

  return (
    <section id="projects" className="section2">
      <h2>My Projects</h2>
      <div className="cards">
        {projects.map((project, index) => (
          <div className="card" key={index}>
            <img src={project.image} alt={project.title} />
            <div className="card-content">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Section2;
